<template>
  <base-dialog-form
    :dialog="dialog"
    :loading="loading"
    :width="400"
    :title="this.product ? 'Edit product' : 'Create product' "
    actionText="Save product details"
    @close="$emit('close')"
    @submit="submit()"
  >
    <template #content>
      <v-autocomplete
        dense
        outlined
        no-filter
        hide-selected
        persistent-hint
        item-text="name"
        item-value="brand_uid"
        class="rounded-lg"
        label="Product brand *"
        :items="brands.data"
        v-model="productObj.brand_uid"
        :search-input.sync="brandSearch"
        :hint="errors.get('brand_uid')"
        :error="errors.has('brand_uid')"
        @input="errors.clear('brand_uid')"
      >
        <template v-slot:append>
          <brand-form />
        </template>
      </v-autocomplete>

      <v-text-field
        dense
        outlined
        persistent-hint
        type="number"
        step=".01"
        label="Measurement *"
        class="rounded-lg"
        v-model="productObj.measurement"
        :hint="errors.get('measurement')"
        :error="errors.has('measurement')"
        @input="errors.clear('measurement')"
      ></v-text-field>

      <v-select
        dense
        outlined
        persistent-hint
        item-text="unit"
        item-value="metric_uid"
        :items="metrics.data"
        label="Metric unit"
        class="rounded-lg"
        v-model="productObj.metric_uid"
        :hint="errors.get('metric_uid')"
        :error="errors.has('metric_uid')"
        @input="errors.clear('metric_uid')"
      ></v-select>
    </template>
  </base-dialog-form>
</template>

<script>
import Product from '@/libs/retail/Product'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    dialog: {
      required: true,
      type: Boolean,
    },

    product: {
      type: Object
    }
  },

  components: {
    'brand-form': () => import('@/components/retail/BrandForm.vue'),
  },

  data () {
    return {
      loading: false,
      brandSearch: '',
      productObj: new Product(),
    }
  },

  computed: {
    ...mapGetters({
      brands: 'getBrands',
      metrics: 'getMetrics',
    }),

    errors () {
      return this.productObj.form.errors
    },
  },

  methods: {
    ...mapActions([
      'setBrands',
      'setMetrics',
      'setProducts',
    ]),

    store () {
      this.productObj.store().then(() => {
        this.setProducts().then(() => {
          this.$emit('close')
        })
      }).finally(() => {
        this.loading = false
      }) 
    },

    update () {
      this.productObj.update(this.product.product_uid)
        .finally(() => {
          this.loading = false
        }) 
    },

    submit () {
      if (!this.loading) {
        this.loading = true
        this.product ? this.update() : this.store()
      }
    },
  },

  mounted () {
    this.setMetrics().then(() => this.setBrands())
//     if (this.product) {
//       Object.keys(this.productObj).forEach(key => {
//         if (this.product.hasOwnProperty(key)) {
//           this.productObj[key] = this.product[key]
//         }
//       })
//     }
  }
}
</script>