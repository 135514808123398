import Base from '@/libs/core/Base'

const fields = {
  brand_uid: null,
  measurement: null,
  metric_uid: null,
}

export default class Product extends Base {
  constructor () {
    super(fields);
  }

  store () {
    return new Promise(async (resolve, reject) => {
      try {
        const data = this.getFields()
        const response = await this.form.submit('post', 'retail/products', data)
        this.setFields(fields)
        resolve(response)
      } catch (err) {
        reject(err)
      }
    })
  }

  update (productUid) {
    return new Promise(async (resolve, reject) => {
      try {
        const data = this.getFields()
        const response = await this.form.submit('patch', `retail/products/${productUid}`, data)
        this.setFields(fields)
        resolve(response)
      } catch (err) {
        reject(err)
      }
    })
  }
}
